@import '~antd/dist/antd.css';

.ant-modal {
  width: 70% !important;
}

.ant-spin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-spin-dot {
    font-size: 30px;
  }
}

.ant-spin-dot-item {
  height: 14px;
  width: 14px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
